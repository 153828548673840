import React, { useEffect, useState } from 'react';
import { DatePicker, Breadcrumb, message, } from 'antd';
import axios from 'axios';
import axiosConfig from '../../../BaseUrl';
const { RangePicker } = DatePicker;



const AddTalabaSuperAdmin = () => {

    const [ttj, setTTJ] = useState();
    const [block, settBlock] = useState()
    const [floor, setFloor] = useState()
    const [xona, setXona] = useState()
    const [joy, setJoy] = useState()

    const [userProfileData, setUserProfileData] = useState({
        hemis_id: "",
        jshshir: "",
        fullname: "",
        gender: "",
        phone: "",
        province: "",
        district: "",
        education_form: "",
        payment_form: "",
        department: "",
        specialty: "",
        level: "",
        accommodation_id: "",
        block_id: "",
        floor_id: "",
        room_id: "",
        place_id: "",
        start_date: "",
        end_date: ""
    });

    const token = sessionStorage.getItem('token')
    const getHemisData = () => {
        if (userProfileData?.hemis_id?.length === 12) {
            axios.get(`https://ttjapi.adu.uz/api/admin/student-list?search=${userProfileData.hemis_id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }).then(res => {
                console.log(res.data.data.items);
                setUserProfileData((item) => {
                    return {
                        ...item,
                        ...{
                            fullname: res.data.data.items[0]?.full_name,
                            gender: res.data.data.items[0]?.gender.name,
                            province: res.data.data.items[0]?.province.name,
                            district: res.data.data.items[0]?.district.name,
                            education_form: res.data.data.items[0]?.educationForm.name,
                            payment_form: res.data.data.items[0]?.paymentForm.name,
                            department: res.data.data.items[0]?.department.name,
                            specialty: res.data.data.items[0]?.specialty.name,
                            level: res.data.data.items[0]?.level.name,
                        }
                    }
                })
            }).catch(err => {
                console.log(err);
            })
        }
    }

    const getTTJ = () => {
        axiosConfig.get(`/admin/accommodation-freeplaces`).then(res => {
            setTTJ(res.data.accommodations)
        }).catch(err => {
            console.log(err);
        })
    }
    const getBlock = () => {
        if (userProfileData.accommodation_id) {
            axiosConfig.get(`/admin/block-freeplaces/${userProfileData.accommodation_id}`).then(res => {
                settBlock(res.data.blocks)
                // console.log(res.data.blocks);
            }).catch(err => {
                console.log(err);
            })
        }
    }

    const getEtaj = () => {
        if (userProfileData.block_id) {
            axiosConfig.get(`/admin/floor-freeplaces/${userProfileData.block_id}`).then(res => {
                setFloor(res.data.floors)
                // console.log(res.data.floors);
            }).catch(err => {
                console.log(err);
            })
        }
    }

    const getXona = () => {
        if (userProfileData.floor_id) {
            axiosConfig.get(`/admin/room-freeplaces/${userProfileData.floor_id}`).then(res => {
                // console.log(res.data.rooms);
                setXona(res.data.rooms)
            }).catch(err => {
                console.log(err);
            })
        }
    }

    const getJoy = () => {
        if (userProfileData.room_id) {
            axiosConfig.get(`/admin/place-freeplaces/${userProfileData.room_id}`).then(res => {
                // console.log(res.data.places);
                setJoy(res.data.places)
            }).catch(err => {
                console.log(err);
            })
        }
    }


    useEffect(() => {
        getTTJ()
    }, [])

    useEffect(() => {
        getBlock()
    }, [userProfileData.accommodation_id])

    useEffect(() => {
        getEtaj()
    }, [userProfileData.block_id])

    useEffect(() => {
        getXona()
    }, [userProfileData.floor_id])

    useEffect(() => {
        getJoy()
    }, [userProfileData.room_id])

    useEffect(() => {
        getHemisData()
    }, [userProfileData.hemis_id])

    const handleChange = (e) => {
        setUserProfileData(() => {
            return {
                ...userProfileData,
                [e.target.name]: e.target.value,
            };
        });
    };


    const connnectStudentPlace = () => {
        axiosConfig.post(`/admin/student-attach`, userProfileData).then(res => {
            // console.log("res",res.data.message);
            // console.log("user data",userProfileData);
            message.success(res.data.message)
            setUserProfileData({
                hemis_id: "",
                jshshir: "",
                fullname: "",
                gender: "",
                phone: "",
                province: "",
                district: "",
                education_form: "",
                payment_form: "",
                department: "",
                specialty: "",
                level: "",
            })
        }).catch(err => {
            // console.log("error", err.response.data);
            // console.log("user data", userProfileData);
            message.error(err.response.data.errors)
        })
    }


    return (
        <div>
            <div>
                <Breadcrumb
                    items={[
                        {
                            title: <h4>Talaba joylashtirish</h4>,
                        },

                    ]}
                />
            </div>
            <hr />
            <div className="container-fluid">
                <div className="row" style={{ justifyContent: "center" }}>
                    <div className="col-md-6">
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="row ">
                                    <div className="col-sm-3">
                                        <h6 className="mb-0">Hemis ID</h6>
                                    </div>
                                    <div className="col-sm-9 text-secondary">
                                        <input className="form-control shadow-none" name="hemis_id" defaultValue={userProfileData.hemis_id} onChange={(e) => handleChange(e)} placeholder="Hemis ID" />
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-sm-3">
                                        <h6 className="mb-0">J.SH.SH.I.R</h6>
                                    </div>
                                    <div className="col-sm-9 text-secondary">
                                        <input onChange={(e) => handleChange(e)} name='jshshir' className="form-control shadow-none" type="text" placeholder="J.SH.SH.I.R" />
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    {
                                        userProfileData?.hemis_id?.length === 12
                                            ?
                                            <div>
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <h6 className="mb-0">Ism familiya</h6>
                                                    </div>
                                                    <div className="col-sm-9 text-secondary">
                                                        <input className="form-control shadow-none" disabled value={userProfileData?.fullname} placeholder="Ism familiya" />
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <h6 className="mb-0">Jinsi</h6>
                                                    </div>
                                                    <div className="col-sm-9 text-secondary">
                                                        <input className="form-control shadow-none" disabled value={userProfileData?.gender} placeholder="Jinsi" />
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <h6 className="mb-0">Tel nomeri</h6>
                                                    </div>
                                                    <div className="col-sm-9 text-secondary">
                                                        <input name='phone' onChange={(e) => handleChange(e)} className="form-control shadow-none" placeholder="Tel nomeri" />
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <h6 className="mb-0">Viloyati</h6>
                                                    </div>
                                                    <div className="col-sm-9 text-secondary">
                                                        <input className="form-control shadow-none" disabled value={userProfileData?.province} placeholder="Viloyati " />
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <h6 className="mb-0">Tumani</h6>
                                                    </div>
                                                    <div className="col-sm-9 text-secondary">
                                                        <input className="form-control shadow-none" disabled value={userProfileData?.district} placeholder="Tumani" />
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <h6 className="mb-0">Ta'lim shakli</h6>
                                                    </div>
                                                    <div className="col-sm-9 text-secondary">
                                                        <input className="form-control shadow-none" disabled value={userProfileData?.education_form} placeholder="Ta'lim shakli" />
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <h6 className="mb-0">Ta'lim turi</h6>
                                                    </div>
                                                    <div className="col-sm-9 text-secondary">
                                                        <input className="form-control shadow-none" disabled value={userProfileData.payment_form} placeholder="Ta'lim turi" />
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row ">
                                                    <div className="col-sm-3">
                                                        <h6 className="mb-0">Fakulteti</h6>
                                                    </div>
                                                    <div className="col-sm-9 text-secondary">

                                                        <input className="form-control shadow-none" disabled value={userProfileData?.department} placeholder="Fakulteti" />
                                                    </div>
                                                </div>
                                            </div> : ""

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        {
                            userProfileData?.hemis_id?.length === 12
                                ?
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <h6 className="mb-0">Yo'nalishi</h6>
                                            </div>
                                            <div className="col-sm-9 text-secondary">
                                                <input className="form-control shadow-none" disabled value={userProfileData?.specialty} type="text" placeholder="Yo'nalishi" />
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <h6 className="mb-0">Kursi</h6>
                                            </div>
                                            <div className="col-sm-9 text-secondary">
                                                <input className="form-control shadow-none" disabled value={userProfileData?.level} placeholder="Kursi" />
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <h6 className="mb-0">TTJ nomi</h6>
                                            </div>
                                            <div className="col-sm-9 text-secondary">

                                                <select onChange={(e) => handleChange(e)} name='accommodation_id' style={{ boxShadow: "none" }} className='form-select'>
                                                    <option selected disabled>Tanlang</option>
                                                    {
                                                        ttj?.map((item, index) => (
                                                            <option key={index} value={item.id}>{item.name}</option>
                                                        ))
                                                    }
                                                </select>

                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <h6 className="mb-0">Block nomi</h6>
                                            </div>
                                            <div className="col-sm-9 text-secondary">
                                                <select onChange={(e) => handleChange(e)} name='block_id' style={{ boxShadow: "none" }} className='form-select'>
                                                    <option selected disabled>Tanlang</option>
                                                    {
                                                        block?.map((item, index) => (
                                                            <option key={index} value={item.id}>{item.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <h6 className="mb-0">Qavat</h6>
                                            </div>
                                            <div className="col-sm-9 text-secondary">
                                                <select onChange={(e) => handleChange(e)} name='floor_id' style={{ boxShadow: "none" }} className='form-select'>
                                                    <option selected disabled>Tanlang</option>
                                                    {
                                                        floor?.map((item, index) => (
                                                            <option key={index} value={item.id}>{item.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <h6 className="mb-0">Xona</h6>
                                            </div>
                                            <div className="col-sm-9 text-secondary">
                                                <select onChange={(e) => handleChange(e)} name='room_id' style={{ boxShadow: "none" }} className='form-select'>
                                                    <option selected disabled>Tanlang</option>
                                                    {
                                                        xona?.map((item, index) => (
                                                            <option key={index} value={item.id}>{item.name}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <h6 className="mb-0">Joy</h6>
                                            </div>
                                            <div className="col-sm-9 text-secondary mb-1">
                                                <select onChange={(e) => handleChange(e)} name='place_id' style={{ boxShadow: "none" }} className='form-select'>
                                                    <option selected disabled>Tanlang</option>
                                                    {
                                                        joy?.map((item, index) => (
                                                            <option key={index} value={item.id}>{item.name}</option>
                                                        ))
                                                    }
                                                </select>

                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <h6 className="mb-0">TTJga joylashgan kuni</h6>
                                            </div>
                                            <div className="col-sm-9 text-secondary mb-3">
                                                <input style={{ boxShadow: "none" }} onChange={(e) => handleChange(e)} name='start_date' className='form-control' type="date" />

                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <h6 className="mb-0">Shartnoma tugash muddati</h6>
                                            </div>
                                            <div className="col-sm-9 text-secondary mb-3">
                                                
                                                <select style={{ boxShadow: "none" }} name='end_date' className='form-select' onChange={(e) => handleChange(e)}>
                                                    <option disabled >Muddatni tanlang</option>
                                                    <option value={"1"}>1 Oy</option>
                                                    <option value={"2"}>2 Oy</option>
                                                    <option value={"3"}>3 Oy</option>
                                                    <option value={"4"}>4 Oy</option>
                                                    <option value={"5"}>5 Oy</option>
                                                    <option value={"6"}>6 Oy</option>
                                                    <option value={"7"}>7 Oy</option>
                                                    <option value={"8"}>8 Oy</option>
                                                    <option value={"9"}>9 Oy</option>
                                                    <option value={"10"}>10 Oy</option>
                                                </select>
                                            </div>
                                        </div>
                                        <hr />
                                        <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                                            <button className='btn btn-primary' onClick={connnectStudentPlace}>Joylash</button>
                                        </div>
                                    </div>
                                </div>
                                : ""
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AddTalabaSuperAdmin;