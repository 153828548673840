import React, { useEffect, useState, useRef } from "react";
import axiosConfig from "../../../BaseUrl";
import { Button, Input, Space, Table, Breadcrumb, Modal, message, Form } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";

const StudentsList = () => {


    const [data, setData] = useState();
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [form] = Form.useForm();

    const getSudentList = () => {
        axiosConfig.get(`/super-admin/students`).then(res => {
            // console.log(res.data.students);
            setData(res.data.students)
        }).catch(err => {
            console.log(err);
        })
    }

    useEffect(() => {
        getSudentList()
    }, [])

    const handleOk = () => {
        setOpen(false);
    };
    const handleCancel = () => {
        setOpen(false);
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };


    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const [open, setOpen] = useState(false);
    const [excel, setExcel] = useState()

    const showModal = () => {
        setOpen(true);
    };
    const formData = new FormData();
    const onFinish = () => {
        formData.append('excel', excel)
        handleCancel()
        axiosConfig.post(`/super-admin/import-payment`,  formData ).then(res => {
            // console.log(res.data);
            message.success(res.data.message)
            form.resetFields();
        }).catch(err => {
            // console.log(err.response.data.errors);
            message.error(err.response.data.errors)
        })
    };


    const columns = [
        {
            title: 'ID',
            dataIndex: 'address',
            key: 'address',
            width: '5%',
            render: (text, row) => (
                <>
                    {row.id}
                </>
            ),
        },
        {
            title: 'F.I.O',
            dataIndex: 'fullname',
            key: 'fullname',
            width: '20%',
            ...getColumnSearchProps('fullname'),
        },
        {
            title: 'HEMIS ID',
            dataIndex: 'hemis_id',
            key: 'hemis_id',
            width: '15%',
            ...getColumnSearchProps('hemis_id'),
        },
        {
            title: 'TTJ nomi',
            render: (text, row) => (
                <>
                    {row.accommodation.name}
                </>
            ),
            width: '10%',
        },
        {
            title: 'Blok nomi',
            width: '10%',
            render: (text, row) => (
                <>
                    {row.block.name}
                </>
            ),
        },
        {
            title: 'Qavati',
            width: '10%',
            render: (text, row) => (
                <>
                    {row.floor.name}
                </>
            ),
        },
        {
            title: 'Xona',
            width: '10%',
            render: (text, row) => (
                <>
                    {row.room.name}
                </>
            ),
        },
        {
            title: 'Joy',
            width: '10%',
            render: (text, row) => (
                <>
                    {row.place.name}
                </>
            ),
        },
        {
            title: 'Joylashgan sanasi',
            width: '10%',
            dataIndex: 'start_date',
            key: 'start_date',
            render: (text, row) => (
                (new Date(row.start_date)).toLocaleDateString()
            )
        },
        {
            title: 'Muddati',
            width: '10%',
            dataIndex: 'end_date',
            key: 'end_date',
            render: (text, row) => (
                <>
                    {row.end_date + " oy"}
                </>
            ),
        },
        {
            title: `To'lagan summa`,
            width: '10%',
            dataIndex: 'payment_summa',
            key: 'payment_summa',
            // render: (text, row) => (
            //     <>
            //         {row.end_date + " oy"}
            //     </>
            // ),
        },


        // {
        //     title: 'Amallar',
        //     width: '20%',
        //     render: (text, row) => (
        //         <div>

        //         </div>
        //     ),
        // },
    ];

    return (
        <div>
            <div style={{display:"flex", justifyContent:"space-between"}}>
                <div>
                    <Breadcrumb
                        items={[
                            {
                                title: `Talabalar ro'yxati`,
                            },

                        ]}
                    />
                </div>
                <div>
                    <Button type="primary" onClick={showModal} >
                        To'lov qo'shish
                    </Button>
                </div>
            </div>
            <hr />
            <Table columns={columns} dataSource={data} />
            <Modal
                open={open}
                title="To'lovlarni qo'shish"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={() => (
                    <div>
                        <div>
                            <div>
                                 <Button type="primary" htmlType="submit" onClick={onFinish}>Qo'shish</Button> 
                                
                            </div>
                        </div>

                    </div>
                )}
            >
                <div>
                    <label className="mt-2" htmlFor="">excel faylni yuklang</label>
                    <input  style={{ boxShadow: "none" }} required placeholder="TTJ nomini kiriting" className="form-control" onChange={(e)=>setExcel(e.target.files[0])}  type="file" />
                </div>
            </Modal>
        </div>
    )
}

export default StudentsList;