import React, { useState, useRef, useEffect } from "react";
import { EyeTwoTone, EditOutlined, HomeOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Modal, Table, Form, Breadcrumb, message, } from 'antd';
import Highlighter from 'react-highlight-words';
import axiosConfig from "../../../BaseUrl";
import { useNavigate, Link } from "react-router-dom";



const CreateEtaj = () => {

    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [name, setName] = useState("")
    const navigate = useNavigate()
    const [active, setActive] = useState('')
    const [id, setId] = useState('')
    const [form] = Form.useForm();

    const showModal = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setOpen(false);
    };
    const handleCancel = () => {
        setName("")
        setOpen(false);
        setId("")
    };

    const showModal2 = () => {
        setOpen2(true);
    };
    const handleOk2 = () => {
        setOpen2(false);
    };
    const handleCancel2 = () => {
        setName("")
        setOpen2(false);
        setId("")
    };

    const accommodation_id = sessionStorage.getItem("ttId")
    const block_id = sessionStorage.getItem("BlockId")

    const onFinish = () => {
        handleCancel()
        axiosConfig.post(`/admin/floor`, { name, accommodation_id, block_id }).then(res => {
            // console.log(res.data);
            message.success(res.data.message)
            setName("")
            form.resetFields();
            getEtaj()
        }).catch(err => {
            // console.log(err.response.data);
            message.error(err.response.data.errors)
        })
    };


    const [data, setData] = useState();

    const getEtaj = () => {
        axiosConfig.get(`/admin/floor/${block_id}`).then(res => {
            setData(res.data.floors)
            // console.log(res.data.floors);
        }).catch(err => {
            console.log(err);
        })
    }

    const getEdit = (id) => {
        axiosConfig.get(`/admin/floor/show/${id}`).then(res => {
            // console.log(res.data.block);
            // message.success(res.data.message)
            setName(res.data.floor.name)
            showModal2()
            // getTTJ()
        }).catch(err => {
            console.log(err);
        })
    }

    const editeEtaj = () => {
        axiosConfig.put(`/admin/floor/edit/${id}`, { name, active, accommodation_id, block_id }).then(res => {
            // console.log(res.data);
            // message.success(res.data.message)
            setName("")
            setActive("")
            getEtaj()
            handleCancel2()
        }).catch(err => {
            // console.log(err.response.data.errors);
            message.error(err.response.data.errors)
            // handleCancel2()
        })
    }

    useEffect(() => {
        getEtaj()
    }, [])


    const deleteEtaj = (id) => {
        axiosConfig.delete(`/admin/floor/destroy/${id}`).then(res => {
            // console.log(res.data.message);
            message.success(res.data.message)
            getEtaj()
        }).catch(err => {
            console.log(err);
        })
    }
    const role = sessionStorage.getItem('role')
    const columns = [
        {
            title: '№',
            width: '5%',
            render: (text, row, index) => (
                <>
                    {index + 1}
                </>
            ),
        },
        {
            title: 'TTJ nomi',
            render: (text, row) => (
                <>
                    {row.accommodation.name}
                </>
            ),
            width: '20%',
        },
        {
            title: 'TTJ va Block nomi',
            render: (text, row) => (
                <>
                    {row.block.name}
                </>
            ),
            width: '20%',
        },
        {
            title: 'TTJ va Block qavati',
            dataIndex: 'name',
            key: 'name',
            width: '20%',
        },
        {
            title: 'Holati',
            dataIndex: 'active',
            key: 'active',
            width: '20%',
            render: (text, row) => (
                <div>
                    {row.active == "1" ?
                        <div style={{ height: "25px", width: "70px", display: "flex", alignItems: "center", justifyContent: "center" }} className="btn btn-secondary">Active</div>
                        :
                        <div style={{ height: "25px", width: "70px", display: "flex", alignItems: "center", justifyContent: "center" }} className="btn btn-danger">Passive</div>}
                </div>
            ),
        },
        {
            title: 'Amallar',
            width: '20%',
            render: (text, row) => (
                <div>
                    {
                        role == 3 ? "" : 
                    <EditOutlined style={{ fontSize: "20px", cursor: "pointer" }} onClick={() => { getEdit(row.id); setId(row.id); showModal2() }} />
                    }
                    <EyeTwoTone style={{ fontSize: "20px", marginLeft: "25px", cursor: "pointer" }}
                        onClick={() => {
                            navigate('/super admin dashboard/create xona');
                            sessionStorage.setItem("floorId", row.id)
                        }} />
                    {/* <DeleteOutlined style={{ fontSize: "20px", marginLeft: "20px", cursor: "pointer", color: "red" }} onClick={() => deleteEtaj(row.id)} /> */}
                </div>
            ),
        },
    ];

    // console.log(checkActive);

    return (
        <div>
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <Breadcrumb
                    items={[
                        {
                            title: <Link to={"/super admin dashboard"}> <HomeOutlined /> TTJ</Link>,
                        },
                        {
                            title: <Link to={"/super admin dashboard/create block"}> Block</Link>,
                        },
                        {
                            title: 'Qavat',
                        },

                    ]}
                />
                {
                    role == 3 ? "" :
                <Button type="primary" onClick={showModal} >
                    Etaj qo'shish
                </Button>
                }
            </div>
            <hr />
            <Table columns={columns} dataSource={data} pagination={false} />
            <Modal
                open={open}
                title="Block ga qavat biriktirish"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={() => (
                    <div>
                        <div>
                            <div>
                                {
                                    name ? <Button type="primary" htmlType="submit" onClick={onFinish}>Qo'shish</Button> : <Button type="primary" htmlType="submit" disabled>Qo'shish</Button>
                                }
                            </div>
                        </div>

                    </div>
                )}
            >
                <div>
                    <label className="mt-2" htmlFor="">Qavat nomi</label>
                    <input value={name} style={{ boxShadow: "none" }} required placeholder="Qavat nomini kiriting" className="form-control" onChange={(e) => setName(e.target.value)} type="text" />
                </div>
            </Modal>
            <Modal
                open={open2}
                title="Qavatni O'zgartirish"
                onOk={handleOk2}
                onCancel={handleCancel2}
                footer={() => (
                    <div>
                        <div>
                            <Button type="primary" htmlType="submit" onClick={editeEtaj}>O'zgartirish</Button>
                        </div>
                    </div>
                )}
            >
                <div>
                    <label className="mt-2" htmlFor="">Qavat nomi</label>
                    <input value={name} style={{ boxShadow: "none" }} required placeholder="TTJ nomini kiriting" className="form-control" onChange={(e) => setName(e.target.value)} type="text" />
                </div>
                <div>
                    <label className="mt-4" htmlFor="">Holatini tanlng</label>
                    <select className="form-select" style={{ boxShadow: "none" }} onChange={(e) => setActive(e.target.value)} name="" id="">
                        <option disabled selected>Holatni tanlang</option>
                        <option value="1">Active</option>
                        <option value="0">Passive</option>
                    </select>
                </div>
            </Modal>
        </div>
    )
}

export default CreateEtaj;